import React from "react";
import RancherKubernetes from "../images/ebooks/rancher-kubernetes.jpg";

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ]
  }
  ```
*/
export default function Example() {
    return (
        <div className="bg-white">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                <div className="bg-gray-900 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
                    <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
                        <div className="lg:self-center">
                            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                                <span className="block"> Vous voulez en savoir plus ?</span>
                                <span className="block"></span>
                            </h2>
                            <p className="mt-4 text-lg leading-6 text-indigo-200">
                                Besoin de comprendre comment Rancher peut vous aider dans vos déploiements Kubernetes ?
                </p>
                            <span className="hs-cta-wrapper mt-8  border border-transparent rounded-md shadow inline-flex items-center text-base font-medium text-primary-600 hover:bg-indigo-50" id="hs-cta-wrapper-82d58dc2-d1b0-47a6-badf-db6f6f4005c5"><span class="hs-cta-node hs-cta-82d58dc2-d1b0-47a6-badf-db6f6f4005c5" id="hs-cta-82d58dc2-d1b0-47a6-badf-db6f6f4005c5">
                                <a href="https://cta-redirect.hubspot.com/cta/redirect/8056686/82d58dc2-d1b0-47a6-badf-db6f6f4005c5" target="_blank" ><img class="hs-cta-img" id="hs-cta-img-82d58dc2-d1b0-47a6-badf-db6f6f4005c5" src="https://no-cache.hubspot.com/cta/default/8056686/82d58dc2-d1b0-47a6-badf-db6f6f4005c5.png" alt="Télécharger le livre blanc" /></a></span><script charset="utf-8" src="https://js.hscta.net/cta/current.js"></script><script type="text/javascript"> hbspt.cta.load(8056686, '82d58dc2-d1b0-47a6-badf-db6f6f4005c5'); </script></span>
                        </div>
                    </div>
                    <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
                        <img
                            className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
                            src={RancherKubernetes}
                            alt="App screenshot"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
