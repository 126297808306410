import React from "react";
import Img from "gatsby-image";
import { RichText } from "prismic-reactjs";

const IconText = (props) => {
  return (
    <div>
      <div className="flex items-center justify-center h-24 w-24 rounded-md text-white">
      {props.icon ? <img src={props.icon}/> : 
        <svg
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
          />
        </svg>}
      </div>
      <div className="mt-5">
        <h5 className="text-lg leading-6 font-medium text-gray-900">
          {props.title}
        </h5>
        <p className="mt-2 text-base leading-6 text-gray-500">
          <RichText render={props.desc} />       </p>
      </div>
    </div>

  );
};

export default IconText;
