import React from "react";

const Calendar = (_) => {
  //   if (typeof window !== "undefined"){
  //       hbspt.cta.load(8056686, "09f0144d-28ad-4587-8be6-9a851ac31fdf", {})
  //   }

  return (
    <div>
      <div
        className="meetings-iframe-container"
        data-src="https://events.zenops.fr/meetings/arnaud59/speed-dating-consulting-suse?embed=true"
      >
        <iframe
          src="https://events.zenops.fr/meetings/arnaud59/speed-dating-consulting-suse?embed=true&amp;parentHubspotUtk=0e96ed8c417d6c1fb46a4177a8fb3f6e&amp;parentPageUrl=null/Users/martinpicod/zenops_website/src/components/signature.html"
          style={{
            minWidth: "312px",
            minHeight: "516px",
            height: "756px",
            border: "medium none",
          }}
          width="100%"
        ></iframe>
      </div>{" "}
      <script
        type="text/javascript"
        src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
      ></script>
    </div>
  );
};

export default Calendar;
