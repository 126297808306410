import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Footer from "../components/Footer";
import Nav from "../components/nav";
import Video from "../components/Video";
import { RichText } from "prismic-reactjs";
import CTAWithImage from "../components/CTAWithImage";
import WhyRancher from "../images/rancher/whyrancher.svg";
import Calendar from "../components/calendar";
import IconText from "../components/IconText";


const RancherPage = ({ data: { rancherPage } }) => {
  const { data } = rancherPage;
  return (
    <Layout
      title={data.page_title.text}
      description={data.page_description.text}
      thumbnail={data.share_preview.url}>
      <Nav isDark></Nav>
      <header className="pt-24 sm:pt-24 md:pt-24 lg:pt-56 bg-gray-900 pb-24">
        <div className="mx-auto max-w-screen-xl">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
              <div>
                <h1 className="mt-4 mb-8 text-3xl tracking-tight leading-10 font-bold text-white sm:mt-5 sm:leading-none sm:text-5xl lg:mt-6 lg:text-4xl xl:text-5xl">
                  <span className="text-primary-400">Zen</span>Ops <br />{" "}
                  {data.page_title.text}
                </h1>
                <div className="mt-6 rounded-md shadow sm:flex-shrink-0 w-auto inline-block">
                  <div className="mt-6 rounded-md shadow sm:flex-shrink-0 w-auto inline-block">
                    <a
                      href="#contact"
                      className="hover:-translate-y-1 transform w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring transition duration-150 ease-in-out">
                      Demandez une démo !
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-span-6 flex justify-center items-center">
              <div className="px-4 py-8 sm:px-10">
                <svg style={{ width: "250px" }} viewBox="0 0 474.72 586.65">
                  <line
                    class="stroke-primary path"
                    x1="94.29"
                    y1="195.13"
                    x2="238.39"
                    y2="423.98"
                  />

                  <line
                    class="stroke-primary path"
                    x2="176.01"
                    y2="195.13"
                    x1="320.13"
                    y1="423.98"
                  />

                  <line
                    class="stroke-primary path"
                    x1="225"
                    y1="143.15"
                    x2="399.53"
                    y2="420.29"
                  />

                  <circle
                    class="fill-white"
                    cx="351.65"
                    cy="463.57"
                    r="123.07"
                  />
                  <circle
                    class="fill-white"
                    cx="123.69"
                    cy="123.69"
                    r="123.69"
                  />
                  <path
                    class="fill-primary-900"
                    d="M56.72,162.23l61.62-74.54h-60V69.23h84.42V85.2l-59.68,74.42h60v18.6H56.72V162.23z"
                  />
                  <path
                    class="fill-grey-900"
                    d="M116.96,151.96c-1.03-0.9-2-1.88-2.91-2.91c-2.17-2.81-3.92-5.92-5.19-9.23l-9.62,12.14
	H116.96z"
                  />
                  <path
                    class="fill-grey-900"
                    d="M186.79,103.85c-2.49-6.53-6.13-12.57-10.75-17.82c-4.67-5.28-10.33-9.59-16.66-12.69
	c-2.86-1.38-5.85-2.47-8.93-3.23v20.15c0.55,0.23-0.23-0.14,0.35,0.14c3.87,1.94,7.27,4.7,9.97,8.08c2.78,3.48,4.92,7.42,6.3,11.65
	c1.52,4.4,2.3,9.03,2.31,13.68c0,4.51-0.7,9-2.08,13.29c-1.34,4.25-3.42,8.22-6.14,11.75c-2.69,3.47-6.1,6.32-9.99,8.35h-0.18v19.85
	c2.67-0.63,5.28-1.49,7.8-2.56c6.37-2.9,12.11-7.02,16.89-12.14c4.73-5.11,8.48-11.05,11.05-17.52c2.65-6.6,4.01-13.65,3.99-20.77
	C190.66,117.14,189.33,110.29,186.79,103.85z"
                  />
                  <path
                    class="fill-grey-900"
                    d="M90.88,150L90.88,150l15.28-19.22l0,0L90.88,150z"
                  />
                  <path
                    class="fill-grey-900"
                    d="M92.03,95.42c-1.43,2.36-2.69,4.82-3.78,7.36c-1.68,4.29-2.8,8.77-3.35,13.34L102,95.35
	L92.03,95.42z"
                  />
                  <g class="fill-blue-rancher">
                    <g>
                      <path d="M408.3,426.6l-1.2-7.4c-0.5-2.3-1.3-4.3-2.1-4.3s-1.4,2-1.4,4.4v2c0,2.4-2,4.3-4.4,4.4H397v5.4h7.7c2,0.1,3.7-1.5,3.8-3.4
			C408.5,427.2,408.3,426.9,408.3,426.6"/>
                      <path d="M390.8,420.3h-24.2c-0.1,0-0.2,0-0.4,0.1v-1.1c0-2.4-0.6-4.4-1.3-4.4c-0.7,0-1.6,2-2.1,4.3l-1.2,7.4
			c-0.4,2,0.9,3.8,2.7,4.3c0.3,0.1,0.5,0.1,0.9,0.1h7.3c0.7,0,1.4-0.1,2.1-0.4c-0.2,1.3-1.3,2.2-2.6,2.1h-10.2
			c-1.5,0-2.7-1.2-2.7-2.6c0-0.2,0-0.3,0.1-0.5l1-6.2c0.2-1.5-0.7-2.8-2.1-3.1c-0.2,0-0.3-0.1-0.5-0.1h-50.8c-1,0-2,0.6-2.4,1.6
			l-9.5,14.6c-0.2,0.2-0.2,0.6,0.1,0.8l1.9,2.2c0.2,0.3,0.6,0.4,0.9,0.1l6.5-5.1v31.1c0,1.5,1.2,2.7,2.7,2.7h14.5
			c1.5,0,2.7-1.2,2.7-2.7v-10.8c0-1.5,1.2-2.7,2.7-2.7h35.7c1.5,0,2.7,1.2,2.7,2.7v10.9c0,1.5,1.2,2.7,2.7,2.7h14.4
			c1.5,0,2.7-1.2,2.7-2.7v-11.8h-7.7c-2.4,0-4.3-2-4.4-4.4V442c0-1.3,0.7-2.7,1.8-3.5v8.9c0,2.4,2,4.3,4.4,4.4H391
			c2.4,0,4.3-2,4.4-4.4v-22.6C395.2,422.3,393.3,420.4,390.8,420.3"/>
                      <path d="M258.6,480.2h13.1c6.2,0,10.4,3,10.4,8.8c0,4-2.6,7.5-6.4,8.7c1,0.9,1.7,1.9,2.2,3c1.3,2.7,2.2,5.7,5,5.7
			c0.4,0,0.9-0.1,1.3-0.2l-0.6,5.6c-1,0.2-2.1,0.4-3.2,0.4c-3.8,0-5.9-1.5-8.2-6.5c-1-2.3-2.3-6.2-4-6.2h-1.7v12.6h-7.9V480.2z
			 M266.5,485.9v7.8h2.9c2.3,0,4.9-0.7,4.9-4.1c0-2.8-1.8-3.7-4-3.7C270.4,485.9,266.5,485.9,266.5,485.9z"/>
                      <path d="M292.9,480.2h8l10.7,31.8h-8l-2-6h-10.6l-2,6H282L292.9,480.2z M292.9,500.2h6.8l-2.1-6.7c-0.5-1.8-0.9-3.6-1.1-5.5h-0.2
			c-0.2,1.8-0.7,3.7-1.2,5.4L292.9,500.2z"/>
                      <path d="M311.9,480.2h8.5l8.3,15.8c0.7,1.4,1.6,3.8,2.4,5.7h0.2c-0.1-1.8-0.3-4.3-0.3-6.2v-15.3h6.5V512h-8.3l-8.7-15.7
			c-0.9-1.7-1.6-3.4-2.3-5.2h-0.2c0.1,1.8,0.4,3.9,0.4,5.7v15.3h-6.5C311.8,512.1,311.8,480.2,311.9,480.2z"/>
                      <path d="M339,495.9c0-11.5,5.7-16.3,14.5-16.3c9.3,0,13,5.1,11.9,11.9l-7.3,0.7c0.8-5.1-1.2-7.1-4.8-7.1c-3.4,0-6.2,2.5-6.2,10.7
			c0,8.7,3,10.8,6.5,10.8c3.2,0,5.9-2,5.3-6.2l6.9,0.7c0.5,6.5-4.1,11.2-12.6,11.2C344.6,512.6,339,507.5,339,495.9"/>
                      <path d="M367.5,480.2h7.9v12h9.6v-12h7.9v31.9h-7.9v-13.6h-9.6V512h-7.9C367.4,512,367.4,480.2,367.5,480.2z" />
                      <path d="M396,480.2h21.1v5.9H404v6.8H415v5.8H404v7.4h13.7v5.9h-21.5v-31.8H396z" />
                      <path d="M419,480.2h13.1c6.2,0,10.4,3,10.4,8.8c0,4-2.6,7.5-6.4,8.7c1,0.9,1.7,1.9,2.2,3c1.3,2.7,2.2,5.7,5,5.7
			c0.4,0,0.9-0.1,1.3-0.2l-0.7,5.7c-1,0.2-2.1,0.4-3.2,0.4c-3.8,0-5.9-1.5-8.2-6.5c-1-2.3-2.3-6.2-4-6.2h-1.8v12.6h-7.9L419,480.2z
			 M427,485.9v7.8h2.9c2.3,0,4.9-0.7,4.9-4.1c0-2.8-1.8-3.7-4-3.7C430.8,485.9,427,485.9,427,485.9z"/>
                    </g>
                  </g>
                </svg>


              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="py-12 bg-gray-100 pt-24" id="infra">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 ">
          <h2
            className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-primary-600 sm:leading-10 bg-gray-100 py-2 z-10 lg:text-center"
            style={{ position: "sticky", top: "50px" }}>
            {data.executer_title.text}
          </h2>
          <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto lg:text-center">
            <RichText render={data.executer_description.raw} />
          </p>
          <div className="columns flex flex-wrap items-center max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 features mb-8">
            <div className="w-full md:w-1/2 py-8 px-6 flex flex-col justify-center  ">
              <h3
                className="mt-6 text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10 "
                data-sal="slide-up"
                data-sal-delay="300"
                data-sal-easing="ease-in-out"
                data-sal-duration="500"
              >
                Gestion unifiée des applications multi-clusters
              </h3>
              <div
                className="mt-6 text-lg leading-7 text-gray-500 prose"
                data-sal="slide-up"
                data-sal-delay="300"
                data-sal-easing="ease-in-out"
                data-sal-duration="500"
              >
                SUSE Rancher ne se contente pas de déployer des clusters Kubernetes de qualité production, du centre de données au cloud en passant par la périphérie, il les unit également avec une authentification, un contrôle d'accès et une observabilité centralisés. SUSE Rancher permet de rationaliser le déploiement de clusters sur du bare-métal, des clouds
                privés, des clouds publics ou vSphere et de les sécuriser à l'aide de politiques de sécurité
                globales.
                Utilisez Helm ou le Catalogue d’Applications pour déployer et gérer des applications dans l'un
                ou l'ensemble de ces environnements, en assurant la cohérence multi-clusters avec un seul
                déploiement.
              </div>

            </div>

            <div className="w-full md:w-1/2 p-6 md:block text-center" data-sal="fade">
              <img src={WhyRancher} alt="feature image rancher" />
            </div>
          </div>

          <Video
            src={"577119745"}
            title={
              "Commet Rancher se différentie ?"
            }
            description={"Rancher est la plateforme d'entreprise complète permettant d'exécuter des clusters Kubernetes sur site, dans le cloud ou à la périphérie. Logiciel 100% open-source avec zéro verrouillage, Rancher s'adapte parfaitement à votre stratégie d'orchestration de conteneurs multi-clusters, hybride ou multi-cloud."}
          />
        </div>
      </div>

      <div className="py-12 bg-white">
        <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-screen-xl lg:px-8 ">
          <h2
            className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-primary-600 bg-white sm:leading-10 py-2 z-10 lg:text-center"
            style={{ position: "sticky", top: "50px" }}>
            {data.delivrer_title.text}
          </h2>
          <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto lg:text-center">
            <RichText render={data.delivrer_description.raw} />
          </p>
          <div className="lg:grid lg:grid-cols-2 lg:gap-8 mt-12">
            {data.delivrer_items?.map((i) => (
              <IconText
                title={i.delivrer_item_title.text}
                desc={i.delivrer_item_description.raw}
                icon={i.delivrer_item_icon.url} />
            ))}

          </div>
        </div>
      </div>

<CTAWithImage/>

      {/* CTA Banner  */}
     

      <div className="py-12 bg-gray-900 " id="contact">
        <div className="max-w-l mx-auto lg:max-w-screen-xl ">
          {/* <img
            className="mx-auto  rounded shadow"
            src="https://www.hubspot.fr/hs-fs/hubfs/assets/hubspot.com/parrotdad/feature-pages/sales-meetings-1.png?width=1025&name=sales-meetings-1.png"
          /> */}
          <div className="max-w-3xl mx-auto lg:max-w-none">
            <p className="py-8 mt-2 text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none text-center">
              Plannifiez une démo avec nos consultants SUSE
            </p>
          </div>
          <div
            className="meetings-iframe-container"
            data-src="https://events.zenops.fr/meetings/arnaud59/speed-dating-consulting-suse?embed=true"></div>
          <script
            type="text/javascript"
            src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"></script>
          <Calendar />
        </div>
      </div>
      <Footer title={"ZenOps vous accompagne sur la solution SUSE"} />
    </Layout>
  );
};

export default RancherPage;

export const pageQuery = graphql`
  query RancherQuery {
    rancherPage: prismicRancherpage {
      data {
        page_title {
          text
        }
        page_description {
          text
        }
        title {
          text
        }
        share_preview {
          fluid {
            src
          }
        }
        executer_video_description {
          raw
        }
        executer_title {
          text
        }
        executer_description {
          raw
        }
        delivrer_title {
          text
        }
        delivrer_items {
          delivrer_item_title {
            text
          }
          delivrer_item_description {
            raw
          }
          delivrer_item_icon {
            url
          }
        }
        delivrer_description {
          raw
        }
        executer_video_url
      }
    }
  }
`;
